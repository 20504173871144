<template>
  <div class="members">
    <div class="members__nav-bar">
      <nav-bar />
    </div>
    <members-content />
  </div>
</template>

<script>
export default {
  name: "Members",
  components: {
    MembersContent: () => import('@/components/template/members/MembersContent'),
    NavBar: () => import('@/components/template/members/NavBar')
  },
}
</script>

<style lang="scss" scoped>
.members {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 99px 20px 50px 20px;
  }

  &__nav-bar {
    position: relative;
    max-width: 320px;
    width: 100%;
  }
}
</style>
